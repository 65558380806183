import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"
import parseHTML from "html-react-parser"

import Link from "../../UI/Common/Link"
import * as style from "./PromoBoxes.module.scss"

const PromoBoxes = ({ sectionTitle, boxes }) => {
  return (
    <div className="py-5">
      <Container>
        <h2 className="sr-only">{parseHTML(sectionTitle)}</h2>
        <Row>
          {React.Children.toArray(
            boxes.map((item, i) => {
              return (
                <Col xs={12} md={6}>
                  <div className={`position-relative my-3 my-md-0`}>
                    <div className={style.boxBgImage}>
                      <GatsbyImage
                        image={getImage(item.bkgdImg.localFile)}
                        alt={item.bkgdImg.altText}
                      />
                    </div>
                    <div className={style.bgOverlay} />
                    <div className={promoItemInnerBoxClass}>
                      <h3 className={promoHighlightTextClass}>
                        {parseHTML(item.promoHighlightText)}
                      </h3>
                      <p className={descriptionClass}>
                        {parseHTML(item.description)}
                      </p>
                      <p>
                        <Link
                          className={linkClass}
                          link={item.link.url}
                          title={item.link.title}
                        />
                      </p>
                      {item.logo && (
                        <GatsbyImage
                          className={logoClass}
                          image={getImage(item.logo.localFile)}
                          alt={item.bkgdImg.altText}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              )
            })
          )}
        </Row>
      </Container>
    </div>
  )
}

const promoItemInnerBoxClass = classNames(
  "position-relative",
  "d-flex",
  "flex-column",
  "justify-content-center",
  "align-items-start",
  "p-4",
  "p-md-3",
  "p-lg-5",
  "p-xxl-large",
  style.promoItemInnerBox
)
const promoHighlightTextClass = classNames(
  "px-3",
  "py-2",
  "h4",
  "text-white",
  "text-center",
  "text-uppercase",
  "bg-promo",
  "rounded-pill"
)
const descriptionClass = classNames(
  "mt-3",
  "mb-4",
  "lead",
  "text-white",
  "text-left",
  style.description
)
const linkClass = classNames("text-white", style.link)
const logoClass = classNames("position-absolute", "w-100", style.logo)

export default PromoBoxes
