// extracted by mini-css-extract-plugin
export var bgGrey100 = "PromoBoxes-module--bg-grey-100--50541";
export var bgGrey150 = "PromoBoxes-module--bg-grey-150--3bfb4";
export var bgGrey200 = "PromoBoxes-module--bg-grey-200--5f96c";
export var bgGrey300 = "PromoBoxes-module--bg-grey-300--9f824";
export var bgGrey400 = "PromoBoxes-module--bg-grey-400--a1213";
export var bgGrey500 = "PromoBoxes-module--bg-grey-500--dbba7";
export var bgGrey600 = "PromoBoxes-module--bg-grey-600--d2fe3";
export var bgGrey700 = "PromoBoxes-module--bg-grey-700--320ef";
export var bgGrey800 = "PromoBoxes-module--bg-grey-800--2c8a7";
export var bgGrey900 = "PromoBoxes-module--bg-grey-900--9b52f";
export var bgOverlay = "PromoBoxes-module--bgOverlay--6fcfe";
export var boxBgImage = "PromoBoxes-module--boxBgImage--b8bd0";
export var description = "PromoBoxes-module--description--3b9cf";
export var link = "PromoBoxes-module--link--4ca53";
export var logo = "PromoBoxes-module--logo--64e50";
export var promoItemInnerBox = "PromoBoxes-module--promoItemInnerBox--6a1e6";
export var textGrey100 = "PromoBoxes-module--text-grey-100--53165";
export var textGrey150 = "PromoBoxes-module--text-grey-150--f4e84";
export var textGrey200 = "PromoBoxes-module--text-grey-200--2fe08";
export var textGrey300 = "PromoBoxes-module--text-grey-300--d54f2";
export var textGrey400 = "PromoBoxes-module--text-grey-400--d48d6";
export var textGrey500 = "PromoBoxes-module--text-grey-500--8a6ee";
export var textGrey600 = "PromoBoxes-module--text-grey-600--9531b";
export var textGrey700 = "PromoBoxes-module--text-grey-700--15f9c";
export var textGrey800 = "PromoBoxes-module--text-grey-800--1d899";
export var textGrey900 = "PromoBoxes-module--text-grey-900--c4183";