import { graphql } from "gatsby"
import React from "react"
import PromoBoxes from "./PromoBoxes"

export const fragment = graphql`
 fragment PromoBoxesFragment on WpPage_Flexlayouts_FlexibleLayouts_PromoBoxes {
   sectionTitle
   boxes {
     bkgdImg {
       id
       altText
       sourceUrl
       localFile {
         childImageSharp {
           gatsbyImageData(
             formats: [AUTO, WEBP, AVIF]
             width: 630
             placeholder: NONE
           )
         }
       }
     }
     promoHighlightText
     description
     link {
       title
       url
     }
     logo {
       id
       altText
       sourceUrl
       localFile {
         childImageSharp {
           gatsbyImageData(
             formats: [AUTO, WEBP, AVIF]
             width: 150
             placeholder: NONE
           )
         }
       }
     }
   }
 }
`
export const ACFPromoBoxes = ({ sectionTitle, boxes }) => {  
  return (
    <PromoBoxes
      sectionTitle={sectionTitle}
      boxes={boxes}
    />    
  )
}
